<template>
  <div class="w-full">
    <div class="w-full">
      <div class="w-full relative overflow-hidden">
        <div
          class="
            w-full
            object-cover
            filter
            blur-sm
            transform
            scale-110
            hover:blur-none
            transition
            bg-fixed bg-cover bg-center bg-no-repeat
            image
          "
          style="min-height: 32rem"
        ></div>
        <div
          class="
            absolute
            top-1/2
            left-1/2
            text-white text-center
            transform
            -translate-x-1/2 -translate-y-1/2
          "
        >
          <h1
            data-aos="fade-right"
            class="font-bold text-4xl bg-opacity-40 p-2"
          >
            Silver Shop
          </h1>
          <p data-aos="fade-right" class="text-xl">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Animi
            eaque, reiciendis ducimus explicabo molestias neque illum.
          </p>

          <div data-aos="zoom-in" class="mt-4" id="actions">
            <button
              data-aos="fade-right"
              class="p-2 m-1 bg-green-500 transition rounded hover:bg-green-600"
            >
              Entrez
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full py-12" id="about">
      <div class="text-center px-4 sm:px-56" data-aos="zoom-in">
        <h1 class="mb-2 text-4xl font-semibold text-red-5">About</h1>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto
          hic quod illum suscipit ratione voluptas optio aliquid possimus neque
          explicabo quaerat, dignissimos amet a necessitatibus ipsam asperiores
          odit nihil perferendis!
        </p>

        <div
          v-for="i in 3"
          :key="i"
          data-aos="slide-right"
          class="flex flex-wrap mt-2 items-center"
        >
          <div class="w-6/6 p-2 flex">
            <div
              class="
                flex flex-col
                sm:flex-row
                bg-red-10
                rounded
                text-white
                transform
                transition
                hover:scale-105
              "
            >
              <img
                class="h-1/3 sm:h-full w-full sm:w-1/3 object-cover rounded-l"
                src="../assets/g1.jpg"
                alt=""
              />
              <div class="flex flex-wrap sm:flex-nowrap items-center p-4">
                <div class="w-full sm:w-4/6">
                  <h1 class="text-3xl font-semibold my-2 text-white">
                    Vêtements, accessoires et plus
                  </h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Blanditiis voluptate delectus quae reprehenderit error
                    laboriosam asperiores quos, cumque reiciendis laborum
                    aliquam tempore natus at a voluptas dolores quibusdam
                    exercitationem quis!
                  </p>
                </div>
                <div
                  class="sm:w-2/6 flex items-center justify-end hidden sm:block"
                >
                  <svg
                    class="
                      cursor-pointer
                      transition
                      fill-white
                      hover:fill-red-20
                    "
                    style="width: 12rem; height: 12rem"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4,15V9H12V4.16L19.84,12L12,19.84V15H4Z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="products" class="w-full bg-red-5 py-12">
      <div
        class="text-center px-4 sm:px-56"
        data-aos="zoom-in"
        data-aos-offset="10"
      >
        <h1 class="mb-6 text-4xl font-semibold text-white">Products</h1>
        <div class="flex flex-wrap justify-center">
          <div
            v-for="i in 15"
            :key="i"
            data-aos="zoom-in"
            class="w-full sm:w-6/12 md:w-4/12 p-2"
          >
            <div
              class="
                flex
                bg-white
                rounded
                transition
                cursor-pointer
                transform
                hover:scale-105
                shadow
              "
            >
              <img
                class="w-2/6 h-2/6 object-cover rounded-l"
                src="../assets/g2.png"
                alt=""
              />
              <div class="flex flex-wrap p-2 text-left">
                <div class="w-full flex-grow">
                  <h1 class="text-xl font-semibold">Pepsi</h1>
                  <p>Do you want to buy gift? Come here!</p>
                </div>
                <div class="flex justify-end items-end w-full flex-shrink">
                  <h1
                    class="
                      text-blue-500
                      hover:text-blue-600
                      cursor-pointer
                      flex-grow
                    "
                  >
                    Show More...
                  </h1>
                  <div>
                    <span class="text-green-400 font-semibold">10.4 </span>
                    <span class="text-green-500 font-bold">CHF</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full mt-12">
            <h1
              class="
                text-6xl text-white
                font-semibold
                cursor-pointer
                rounded
                transition
                hover:text-gray-300
                relative
              "
            >
              See more...
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div id="communication" class="w-full py-12 px-4 sm:px-56 text-center">
      <h1 class="text-4xl font-semibold text-red-5 mb-4">Communication</h1>
      <div
        data-aos="zoom-in"
        data-aos-offset="100"
        data-aos-delay="250"
        class="flex flex-wrap"
      >
        <div class="w-full sm:w-3/6 p-2 flex justify-center">
          <ul class="w-full">
            <li
              class="
                cursor-pointer
                p-2
                text-center
                flex flex-nowrap
                items-center
                rounded
                hover:bg-gray-200
                w-full
              "
            >
              <svg class="w-14 h-14" viewBox="0 0 24 24">
                <path
                  class="fill-green"
                  d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
                />
              </svg>
              <span class="ml-2 text-2xl font-semibold">+44 1234567 890</span>
            </li>
            <li
              class="
                cursor-pointer
                p-2
                text-center
                flex flex-nowrap
                items-center
                rounded
                hover:bg-gray-200
                w-full
              "
            >
              <svg class="w-14 h-14" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12,15C12.81,15 13.5,14.7 14.11,14.11C14.7,13.5 15,12.81 15,12C15,11.19 14.7,10.5 14.11,9.89C13.5,9.3 12.81,9 12,9C11.19,9 10.5,9.3 9.89,9.89C9.3,10.5 9,11.19 9,12C9,12.81 9.3,13.5 9.89,14.11C10.5,14.7 11.19,15 12,15M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12V13.45C22,14.45 21.65,15.3 21,16C20.3,16.67 19.5,17 18.5,17C17.3,17 16.31,16.5 15.56,15.5C14.56,16.5 13.38,17 12,17C10.63,17 9.45,16.5 8.46,15.54C7.5,14.55 7,13.38 7,12C7,10.63 7.5,9.45 8.46,8.46C9.45,7.5 10.63,7 12,7C13.38,7 14.55,7.5 15.54,8.46C16.5,9.45 17,10.63 17,12V13.45C17,13.86 17.16,14.22 17.46,14.53C17.76,14.84 18.11,15 18.5,15C18.92,15 19.27,14.84 19.57,14.53C19.87,14.22 20,13.86 20,13.45V12C20,9.81 19.23,7.93 17.65,6.35C16.07,4.77 14.19,4 12,4C9.81,4 7.93,4.77 6.35,6.35C4.77,7.93 4,9.81 4,12C4,14.19 4.77,16.07 6.35,17.65C7.93,19.23 9.81,20 12,20H17V22H12C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z"
                />
              </svg>
              <span class="ml-2 text-2xl font-semibold"
                >help@silversshop.ch</span
              >
            </li>
            <li
              class="
                cursor-pointer
                p-2
                text-center
                flex flex-nowrap
                items-center
                rounded
                hover:bg-gray-200
                w-full
              "
            >
              <svg class="w-14 h-14" viewBox="0 0 24 24">
                <path
                  fill="#BE185D"
                  d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                />
              </svg>
              <span class="ml-2 text-2xl font-semibold">silvershop.ch</span>
            </li>
            <li
              class="
                cursor-pointer
                p-2
                text-center
                flex flex-nowrap
                items-center
                rounded
                hover:bg-gray-200
                w-full
              "
            >
              <svg class="h-14 w-14" viewBox="0 0 24 24">
                <path
                  fill="#1D4ED8"
                  d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
                />
              </svg>
              <span class="ml-2 text-2xl font-semibold">SilverShop</span>
            </li>
          </ul>
        </div>
        <div class="w-full sm:w-3/6 p-2 text-right">
          <iframe
            class="rounded-md w-full h-96"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11044.1260736957!2d6.1451442!3d46.209828!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x40f2b31bf37ba6fc!2sSilver%20Shop!5e0!3m2!1str!2str!4v1627545927539!5m2!1str!2str"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      slide: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.image {
  background-image: url("../assets/bg1.png");
}
</style>
