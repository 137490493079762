<template>
  <div id="app" class="h-full">
    <NavbarComponent />
    <div class="w-full">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent";

export default {
  components: {
    NavbarComponent,
  },
};
</script>
