<template>
  <nav
    class="
      px-4
      sm:px-56
      py-2
      sm:py-0
      flex
      justify-between
      w-full
      items-center
      flex-nowrap flex-col
      sm:flex-row
      bg-red
      text-white
    "
  >
    <div class="flex w-full sm:w-max items-center">
      <svg
        @click="nav = !nav"
        class="h-12 w-12 block sm:hidden cursor-pointer"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
        />
      </svg>

      <h1 class="text-2xl ml-6 sm:ml-0 w-full font-semibold my-2 sm:my-0">
        Silver Shop
      </h1>
    </div>
    <ul
      class="sm:block w-full text-center sm:w-max"
      v-bind:class="{ hidden: !nav }"
    >
      <router-link
        tag="li"
        to="/#home"
        class="sm:float-left p-4 text-lg hover:bg-red-10 cursor-pointer"
      >
        Home
      </router-link>
      <router-link
        tag="li"
        to="/#about"
        class="sm:float-left p-4 text-lg hover:bg-red-10 cursor-pointer"
      >
        About
      </router-link>
      <router-link
        to="/products"
        tag="li"
        class="sm:float-left p-4 text-lg hover:bg-red-10 cursor-pointer"
      >
        Products
      </router-link>
      <router-link
        tag="li"
        to="/#communication"
        class="sm:float-left p-4 text-lg hover:bg-red-10 cursor-pointer"
      >
        Communication
      </router-link>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      nav: false,
    };
  },
};
</script>
