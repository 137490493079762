import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  offset: 50,
});

import "@/styles/index.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
